import React, { Component } from 'react';
import { TimelineLite } from 'gsap/all';



class HomeStrap extends Component{
    constructor(props) {
		super(props);
		this.titleElement = null;
		this.textElement = null;
		this.brandElement = null;
		this.myTween = new TimelineLite({paused: true});
		this.text = props.text;
		this.title = props.title;
		this.brand = 'A';
		this.featureText = props.text.map((text) =>
			<p className='py-1 font-weight-light'>{text}</p>
		);
    }

	componentDidMount(){
		this.myTween 
		.to(this.brandElement, .1,  {ease: 'power4.in', opacity: 0.8,scale:4})
		.to(this.brandElement, .4,  {ease: 'power4.in', opacity: 0.8,scale:1})
		.from(this.titleElement, .5,  {ease: 'power4.in', opacity: 0, scale: 0.5}, 0)
		.from(this.brandElement, 2,  {ease: 'power4.in', rotation: 360}, 1.2)
		.from(this.textElement, 2,  {ease: 'power4.in', opacity: 0}, 1.2)
		.play();
    }
    
	render(){
		return (
			<div>
				<div ref={div => this.brandElement = div} className='highlight-text brand-element d-inline-block'><span >{this.brand}</span></div>
				<h1 className='font-weight-light' id='animation' ref={h1 => this.titleElement = h1}>
					{this.title}
				</h1>
				<div id='animation' ref={div => this.textElement = div}>{this.featureText}
				</div>
			</div>
		)
	 }
};

export default HomeStrap;
