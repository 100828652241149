import React from 'react'
import Layout from '../components/layout'
import HomeStrap from '../components/animations/homeStrap'
import Featurette from '../components/featurette'
import { 
  Container
} from 'react-bootstrap'

const HomePage = ({data}) => {
  return (
    <>
      <Layout> 
        <section className='white-color gr-minh-75 '>
          <Container className='gr-minh-75 d-flex flex-column justify-content-center'>  
            <HomeStrap  
              className='home-text black-text d-block' 
              title='Enterprise 360 Simple Project Management'
              text={[
                'Secure online project management, time recording and expenses'
              ]}
            />
          </Container>
        </section>
        <Featurette className='scrolled'
          title='Product'
          text={[
            'Enterprise 360 is online project, time and expense software with integrated billing and document storage.',     
            'Project teams cansStart with simple time recording and then progress to sophisticated project planning, billing and management.'
          ]}
          image='myactiv.jpg'
          color='highlight-color'
          textColor='white-text'
          textOrientation='right'
          linkText='Find Out More'
          link='/tour'
        />
        <Featurette
          title='Simple and Comprehensive'
          text={[
            'A simple list of internal and external projects is linked to timesheets, expenses, documents and even a simple CRM system.',
            'It’s all safely hosted in our top of the range data centre and using state of the art Oracle database technology.'
          ]}
          image='f360.jpg'
          color='dark-color'
          textColor='white-text'
          textOrientation='left'
          linkText='Find Out More'
          link='/tour'
        />    
      </Layout>
    </>
  )
}

export default HomePage